import React from 'react';

const CheckMark: React.FC<{ isVisible: boolean }> = ({ isVisible }) => {
  if (!isVisible) return null;

  return (
    <div style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      opacity: 1, // Add some opacity
      pointerEvents: 'none', // Prevent pointer events
      zIndex: 3,
    }}>
      <svg width="84" height="66" viewBox="0 0 84 66" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.0113 20.0749C20.1848 26.4016 25.438 32.8206 31.5534 40.3002C44.8428 26.0085 56.9357 13.0069 69.0312 0C75.4384 6.02854 80.031 10.3527 84 14.0886C66.3754 31.6888 49.0029 49.0383 32.015 66C21.7714 55.6341 11.0184 44.7484 0 33.5936C3.75945 30.2087 8.4528 25.9795 15.0113 20.0749Z" fill="white"/>
      </svg>
    </div>
  );
};

export default CheckMark;