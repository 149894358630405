import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  Typography,
  Divider,
  Box,
} from '@mui/material';
import { addVideoPageToProject } from '../../client/api'; // Import the addVideoPageToProject API function
import { Project, VideoPage } from '../../context/types'; // Import Project type
import { useUserContext } from '../../context/UserContext';

interface CreateVideoPageDialogProps {
  open: boolean;
  onClose: () => void;
  onVideoPageCreated: (newVideoPage: VideoPage) => void; // Callback after video page creation
  projectId: string; // Project ID to which the video page will be added
  loading: boolean; // Loading state
}

const CreateVideoPageDialog: React.FC<CreateVideoPageDialogProps> = ({
  open,
  onClose,
  onVideoPageCreated,
  projectId,
  loading,
}) => {
  const [videoPageName, setVideoPageName] = useState('');
  const { user } = useUserContext();

  const handleCreateNewVideoPage = async () => {
    if (!videoPageName) return;

    try {
      const newVideoPage = await addVideoPageToProject(projectId, videoPageName, user?._id);
      setVideoPageName('');
      onVideoPageCreated(newVideoPage); // Callback with the new video page data
    } catch (error) {
      console.error('Failed to create video page', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent sx={{ textAlign: 'center' }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'primary.main', mb: 1 }}>
          Create a New Video Page
        </Typography>

        <Divider sx={{ mb: 2 }} />

        <TextField
          fullWidth
          variant="outlined"
          label="Video Page Name"
          value={videoPageName}
          onChange={(e) => setVideoPageName(e.target.value)}
          inputProps={{ maxLength: 50 }} // Set a reasonable length limit
          helperText={`${videoPageName.length}/50`}
          sx={{ mb: 3 }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateNewVideoPage}
          disabled={loading} // Disable the button if loading
          fullWidth
        >
          {loading ? 'Creating...' : 'Create Video Page'} {/* Change button text based on loading state */}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default CreateVideoPageDialog;
