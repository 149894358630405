import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import VideoPage from './components/VideoPage';
import MainLayout from './pages/Main';
import { ProjectProvider } from './context/ProjectContext';
import { UserProvider } from './context/UserContext';
import Login from './pages/Auth/Login'; // Import Login Component
import Signup from './pages/Auth/SignUp'; // Import Signup Component
import { NotificationProvider } from './context/NotificationContext';
import ProjectDashboard from './pages/ProjectDashboard';
import InvitationPage from './pages/Invitation';
import AppSelection from './pages/AppSelection';
import Cookies from 'js-cookie';
import { getUserById } from './client/api';

// A higher-order component to protect routes
const ProtectedRoute = ({ children } : any) => {
  // Retrieve the 'authInfo' cookie
  const authInfo = Cookies.get('authInfo');

  // Parse the cookie data to extract the userId
  const userId = authInfo ? JSON.parse(authInfo).userId : null;

  if (!userId) {
    return <Navigate to="/login" replace />;
  }

  return children ? children : <Outlet />;
};

function App() {
  return (
    <NotificationProvider>
      <UserProvider>
        <ProjectProvider>
          <Router>
            <Routes>
              {/* Public Routes */}
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route 
                  path="/invitation/:workspaceId/:encodedInvitationKey" 
                  element={<InvitationPage  />} 
              />
              {/* Protected Routes */}
              <Route path="/" element={<ProtectedRoute><MainLayout /></ProtectedRoute>}>
                <Route index element={<Dashboard />} />
                <Route path="projects/:projectId/video/:videoPageId" element={<VideoPage />} />
                <Route path="projects/:projectId" element={<ProjectDashboard />} />
              </Route>
              <Route path="/app-selection" element={<AppSelection />} />
            </Routes>
          </Router>
        </ProjectProvider>
      </UserProvider>
    </NotificationProvider>
  );
}

export default App;
