import React, { useEffect, useState } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';
import { useUserContext } from '../../context/UserContext';
import { checkBuilderAccess, checkLetsLaunchAccess, getUserById, registerUser } from '../../client/api'; // API call for signup
import LogoContainer from '../../components/LogoContainer';
import Logo from '../../components/Logo';
import useStyles from './styles'; // Import the styles
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const Signup: React.FC = () => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // New state for password confirmation
  const [accessKey, setAccessKey] = useState('');
  const [organizationKey, setOrganizationKey] = useState(''); // New state for organization key
  const [passwordError, setPasswordError] = useState(''); // State for password error message
  const { user, setUser, setCurrentWorkspace } = useUserContext();
  const navigate = useNavigate(); // Initialize useNavigate

const handleSignup = async () => {
  if (password !== confirmPassword) {
    setPasswordError("Passwords do not match."); // Set error message
    return; // Prevent signup
  }

  setPasswordError(''); // Clear any previous error messages

  try {
    const data = await registerUser(name, email, password, 'user', organizationKey /* , accessKey */); // Pass organizationKey
    setUser(data.user);
    setCurrentWorkspace(data.user.ownedWorkspaces[0]);

    // Store user details in a cookie accessible by all subdomains
    const cookieData = {
      userId: data.user._id,
      organizationKey: data.user.organization.organizationKey,
      letsLaunchAccess: data.user.organization.letsLaunchAccess,
    };
    Cookies.set('authInfo', JSON.stringify(cookieData), {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      path: '/',
      secure: true,
      sameSite: 'strict',
    });

    const redirectToBuilder = !!sessionStorage.getItem('redirectToBuilder');

    let hasLetsLaunchAccess = false;
    let hasBuilderAccess = redirectToBuilder;

    if (data.user?.organization) {
      // Check access for Let's Launch and Builder
      hasLetsLaunchAccess = await checkLetsLaunchAccess(data.user.organization.organizationKey);
      hasBuilderAccess = await checkBuilderAccess(data.user.organization.organizationKey);
    }

    localStorage.setItem('userId', data.user._id)
    // Redirect to builder if conditions met
    if (sessionStorage.getItem('redirectToBuilder') || (!hasLetsLaunchAccess && hasBuilderAccess)) {
      sessionStorage.removeItem('redirectToBuilder');
      const builderUrl = process.env.REACT_APP_BUILDER_URL || '/'; // Fallback to root if URL is undefined
      window.location.href = builderUrl;
    } else {
      // Redirect to invitation URL if available
      const invitationUrl = localStorage.getItem('invitationUrl');
      if (invitationUrl) {
        localStorage.removeItem('invitationUrl'); // Clear the invitation URL from localStorage
        navigate(invitationUrl); // Redirect to the invitation URL
      } else if(hasLetsLaunchAccess && hasBuilderAccess){
        navigate('/app-selection')
      } else {
        navigate('/'); // Redirect to homepage
      }
    }
  } catch (error) {
    console.error('Error signing up:', error);
  }
};
  useEffect(() => {
    const checkUserFromCookie = async () => {
      try {
        const authInfo = Cookies.get('authInfo');
        if (!authInfo) return; // No cookie found

        const { userId } = JSON.parse(authInfo);

        // Check if user ID exists
        if (userId) {
          const userData = await getUserById(userId);

          if (userData) {
            // User exists, redirect to homepage
            setUser(userData);
            navigate('/');
          }
        }
      } catch (error) {
        console.error('Error checking user from cookie:', error);
      }
    };
    // Check user from cookie
    checkUserFromCookie();
  }, [navigate, setUser]);

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', padding: 2 }}>
      <LogoContainer>
        <Logo />
        <Typography variant="h6" sx={{ color: '#000', fontWeight: 'bold' }}>
          LetsLaunch
        </Typography>
      </LogoContainer>
      <Typography variant="h5">Sign Up</Typography>
      <br />

      {/* Box for input fields */}
      <Box sx={{ marginBottom: 2 }}>
        <TextField
          required
          fullWidth
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          InputLabelProps={{
            shrink: true, // Keep the label always outside
            className: classes.inputLabel, // Use the custom label style
          }}
          className={classes.textField} // Apply margin style
        />
        <TextField
          required
          fullWidth
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputLabelProps={{
            shrink: true, // Keep the label always outside
            className: classes.inputLabel, // Use the custom label style
          }}
          className={classes.textField} // Apply margin style
        />
        <TextField
          required
          fullWidth
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputLabelProps={{
            shrink: true, // Keep the label always outside
            className: classes.inputLabel, // Use the custom label style
          }}
          className={classes.textField} // Apply margin style
        />
        <TextField
          required
          fullWidth
          label="Confirm Password" // New field for confirmation
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          InputLabelProps={{
            shrink: true, // Keep the label always outside
            className: classes.inputLabel, // Use the custom label style
          }}
          className={classes.textField} // Apply margin style
        />
        {/*<TextField
          required
          fullWidth
          label="Access Key"
          value={accessKey}
          type="password"
          onChange={(e) => setAccessKey(e.target.value)}
          InputLabelProps={{
            shrink: true, // Keep the label always outside
            className: classes.inputLabel, // Use the custom label style
          }}
          className={classes.textField} // Apply margin style
        />*/}
        <TextField
          required
          fullWidth
          label="Organization Key" // New field for organization key
          value={organizationKey}
          type="password"
          onChange={(e) => setOrganizationKey(e.target.value)}
          InputLabelProps={{
            shrink: true, // Keep the label always outside
            className: classes.inputLabel, // Use the custom label style
          }}
          className={classes.textField} // Apply margin style
        />
        {passwordError && ( // Show error message if passwords don't match
          <Typography variant="body2" color="error" sx={{ marginTop: 1 }}>
            {passwordError}
          </Typography>
        )}
      </Box>

      {/* Box for buttons */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Button variant="contained" color="primary" onClick={handleSignup}>
          Sign Up
        </Button>
        <Button variant="text" href="/login">
          Already have an account? Login
        </Button>
      </Box>
    </Box>
  );
};

export default Signup;
