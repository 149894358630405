import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Snackbar,
} from '@mui/material';
import { createWorkspace } from '../../client/api'; // Adjust the import path
import { useUserContext } from '../../context/UserContext';
import { fetchUser } from '../../utils/utils';

const CreateWorkspaceDialog = ({ open, onClose, user, onWorkspaceCreated, handleCreateWorkspace } : any) => {
  const [workspaceName, setWorkspaceName] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage ] = useState('');
  const { setUser, setCurrentWorkspace } = useUserContext();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Create New Workspace</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Workspace Name"
            fullWidth
            value={workspaceName}
            onChange={(e) => setWorkspaceName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={() => handleCreateWorkspace(workspaceName)} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </>
  );
};

export default CreateWorkspaceDialog;
