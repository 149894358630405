import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  Typography,
  Divider,
  Box,
} from '@mui/material';
import { createProject } from '../../client/api'; // Import the createProject API function
import logo1 from '../../assets/logo1.png'; // Assuming logo1 is inside src/assets/
import { Project } from '../../context/types'; // Import Project type
import { useUserContext } from '../../context/UserContext';

interface CreateProjectDialogProps {
  open: boolean;
  onClose: () => void;
  onProjectCreated: (project: Project) => void; // Add this new prop
}

const CreateProjectDialog: React.FC<CreateProjectDialogProps> = ({ open, onClose, onProjectCreated }) => {
  const [projectName, setProjectName] = useState('');
  const [loading, setLoading] = useState(false);
  const { currentWorkspace } = useUserContext();

  const handleCreateNewProject = async () => {
    if (!projectName || !currentWorkspace) return;

    try {
      setLoading(true);
      const newProject = await createProject(projectName, currentWorkspace._id); // Call the API to create the project
      setProjectName('');
      onProjectCreated(newProject); // Call the callback with the new project
    } catch (error) {
      console.error('Failed to create project', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent sx={{ textAlign: 'center' }}>
        {/* Logo */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <img src={logo1} alt="LetsLaunch" style={{ width: '90px', height: '38px' }} />
        </Box>

        <Divider sx={{ mb: 2 }} />

        {/* Title */}
        <Typography variant="h4" sx={{ fontWeight: 'bold',  color: 'primary.main', mb: 1 }}>
          Let’s Launch!
        </Typography>

        <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'black', mb: 2 }}>
          What's the name of your product?
        </Typography>

        {/* Project Name Input */}
        <TextField
          fullWidth
          variant="outlined"
          label="Product Name"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          inputProps={{ maxLength: 50 }}
          sx={{ mb: 3 }}
        />

        {/* Submit Button */}
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleCreateNewProject}
          disabled={!projectName || loading}
          sx={{ backgroundColor: 'primary.main', color: '#000', fontWeight: 'bold' }}
        >
          CONTINUE
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default CreateProjectDialog;
