// components/LogoContainer.tsx
import React from 'react';
import { Box, styled } from '@mui/material';

const StyledLogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
}));

interface LogoContainerProps {
  children: React.ReactNode;
}

const LogoContainer: React.FC<LogoContainerProps> = ({ children }) => {
  return <StyledLogoContainer>{children}</StyledLogoContainer>;
};

export default LogoContainer;