import React from 'react';

interface PlayIconSvgProps {
  width?: number;
  height?: number;
}

const PlayIconSvg: React.FC<PlayIconSvgProps> = ({ width = 17, height = 20 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 9.99966L0 0V20L17 9.99966Z" fill="black" />
    </svg>
  );
};

export default PlayIconSvg;