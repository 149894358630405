import { makeStyles } from "@mui/styles";
import { THEME_COLORS } from "../context/constants";

export const useGlobalStyles = makeStyles({
  secondaryButton: {
    background: 'none',
    border: `1px solid ${THEME_COLORS.yellow}`,
    color: THEME_COLORS.yellow,
    borderRadius: '10px'
  },
});
