import React, { createContext, useContext, useState, useCallback, ReactNode } from 'react';
import { Notification } from '../components/Notification';

// Define a type for the notification context
type NotificationContextType = {
  addNotification: (message: string, type: 'info' | 'error' | 'warning' | 'success') => void;
};

// Create the context for notifications with the correct type
const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

// Custom hook to use notifications
export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

// Provider component to wrap around your app
export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [notifications, setNotifications] = useState<
    { id: string; message: string; type: 'info' | 'error' | 'warning' | 'success' }[]
  >([]);

  // Add a new notification
  const addNotification = useCallback((message: string, type: 'info' | 'error' | 'warning' | 'success') => {
    const id = Math.random().toString(36).substr(2, 9); // Generate a unique ID
    setNotifications(prev => [...prev, { id, message, type }]);

    // Auto-remove notification after 5 seconds
    setTimeout(() => removeNotification(id), 6000);
  }, []);

  // Remove a notification
  const removeNotification = useCallback((id: string) => {
    setNotifications(prev => prev.filter(notification => notification.id !== id));
  }, []);

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      {children}
      {notifications.map(notification => (
        <Notification
          key={notification.id}
          message={notification.message}
          type={notification.type}
          onClose={() => removeNotification(notification.id)}
        />
      ))}
    </NotificationContext.Provider>
  );
};
