import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Breadcrumbs, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Sidebar from '../../components/Sidebar';
import { useProjectContext } from '../../context/ProjectContext';
import { useUserContext } from '../../context/UserContext'; // Import the UserContext
import { getAllProjects, getUserById } from '../../client/api'; // Import the API methods
import { THEME_COLORS } from '../../context/constants';
import Cookies from 'js-cookie';


const MainLayout = () => {
  const location = useLocation();
  const [pathnames, setPathNames] = useState<String[]>(location.pathname.split('/').filter((x) => x));
  const { currentProject, setProjects } = useProjectContext(); // Get the setProjects function from context
  const { user, setUser, currentWorkspace, setCurrentWorkspace } = useUserContext(); // Get the setUser function from UserContext
  const { projectId, videoPageId } = useParams();
  const navigate = useNavigate()

  const fetchProjects = async (workspaceId: string) => {
      if(!workspaceId) return
      try {
        const projectsData = await getAllProjects(1,10,'', workspaceId); // Fetch projects from API
        setProjects(projectsData.projects); // Assuming the API returns projects in a 'projects' key
      } catch (error) {
        console.error('Failed to fetch projects:', error);
      }
  };

  useEffect(() => {
    const fetchUser = async () => {
      const authInfo = Cookies.get('authInfo');

  // Parse the cookie data to extract the userId
      const userId = authInfo ? JSON.parse(authInfo).userId : null;
      if (userId) {
        try {
          const userData = await getUserById(userId); // Fetch user from API
          setUser(userData); // Set the user in the context

          if(!userData || !userData.organization.letsLaunchAccess){
            navigate('/login')
          }

          setCurrentWorkspace(userData.ownedWorkspaces[0])
          await fetchProjects(userData.ownedWorkspaces[0]._id)
        } catch (error) {
          console.error('Failed to fetch user:', error);
        }
      } else {
        navigate('/login')
      }
    };

    fetchUser(); // Call the function to fetch user
    //fetchProjects(); // Call the function to fetch projects
  }, []);

  useEffect(() => {
    if(!currentProject) return
    setPathNames(location.pathname.split('/').filter((x) => x))
    let path = location.pathname.split('/').filter((x) => x)
    if(projectId){
      path[1] = currentProject?.name
    }
    if(videoPageId) {
      const videoPage = currentProject?.videoPages?.find(videoPage => videoPage._id === videoPageId)
      path[2] = 'video pages'
      path[3] = videoPage?.name || ''
    } 
    setPathNames([...path])
  }, [videoPageId, currentProject, projectId, location])

  useEffect(() => {
    if(!currentWorkspace) return 

    fetchProjects(currentWorkspace._id);
  }, [user, currentWorkspace])

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Breadcrumbs aria-label="breadcrumb" sx={{color: THEME_COLORS.darkGray, fontSize: '12px',}}>
          {pathnames.length === 0 ? (
            <Typography color="textPrimary">{currentWorkspace?.name}</Typography>
          ) : (
            pathnames.map((value, index) => (
                <Typography style={{color: THEME_COLORS.darkGray2, fontSize: '13px', textTransform: 'capitalize'}}>
                  {value} 
                </Typography>
            ))
          )}
        </Breadcrumbs>
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
