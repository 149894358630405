import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton, CircularProgress, InputAdornment, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import { getSuggestion } from '../../client/api';
import useStyles from './styles';
import { THEME_COLORS } from '../../context/constants';

interface OpenAIModalProps {
  open: boolean;
  onClose: () => void;
  onUse: (response: string) => void;
  scriptPart: 'headline' | 'body' | 'cta';
  currentPrompt?: string;
  setCurrentPrompt?: (prompt: string) => void;
}

const OpenAIModal: React.FC<OpenAIModalProps> = ({ open, onClose, onUse, scriptPart, currentPrompt, setCurrentPrompt }) => {
  const [prompt, setPrompt] = useState<string>(currentPrompt || '');
  const [maxTokens, setMaxTokens] = useState<any>(50);
  const [openAIResponse, setOpenAIResponse] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showUseButton, setShowUseButton] = useState<boolean>(false);
  const [displayedText, setDisplayedText] = useState('');
  const classes = useStyles();
  const defaultPrompt = {
    headline: 'This is suppose to be a suggestion for a script headline/hook for a video ad. Send only the suggestion, do not say anything else.',
    body: 'This is suppose to be a suggestion for a script body for a video ad. Send only the suggestion, do not say anything else.',
    cta: 'This is suppose to be a suggestion for a script call to action for a video ad. Send only the suggestion, do not say anything else.',

}

  const handleSend = async () => {
    if (prompt.trim() === '') return; // Don't send empty prompts
    setLoading(true);
    setOpenAIResponse('');
    setDisplayedText('');
    const finalPrompt = prompt +'...'+ defaultPrompt[scriptPart];
    try {
      const response = await getSuggestion(finalPrompt, maxTokens);
      if(response.suggestion) {
        setOpenAIResponse(response.suggestion);
        setShowUseButton(true); // Show the 'Use' button once we have a response
      }
      setCurrentPrompt?.(prompt)
    } catch (error) {
      console.error('Error fetching suggestion:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUse = () => {
    onUse(openAIResponse); // Send the answer back to the parent component
    onClose(); // Close the modal
  };

  useEffect(() => {
    if (openAIResponse) {
      // Set a small delay to trigger the animation after response is fetched
      const timer = setTimeout(() => {
        setDisplayedText(openAIResponse);
      }, 100); // Adjust delay as needed
      return () => clearTimeout(timer);
    }
  }, [openAIResponse]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Ask AI for a {scriptPart} suggestion</DialogTitle>
      <DialogContent sx={{ gap: 2}}>
        <TextField
            label="Prompt"
            fullWidth
            multiline
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Describe your product and provide information so the AI can generate a suggestion"
            variant="outlined"
            sx={{
                marginTop: '20px',
                marginBottom: '16px',
                '& .MuiInputLabel-root': {
                color: '#000', // Set label color
                },
                '& .MuiInputLabel-root.Mui-focused': {
                color: '#FFBF00', // Set label color when focused
                },
                '& .MuiOutlinedInput-root': {
                paddingRight: '40px', // Adds space for the button inside
                },
                
            }}
            InputLabelProps={{
                shrink: true, // Ensures the label always stays above
            }}
            
            InputProps={{
                endAdornment: (
                <InputAdornment position="end">
                    <IconButton onClick={handleSend} sx={{ color: '#FFBF00' }}>
                    <SendIcon />
                    </IconButton>
                </InputAdornment>
                ),
            }}
        />
        <TextField
          label="Max size (chars)"
          type="number"
          value={maxTokens}
          onChange={e => setMaxTokens(e.target.value)}
          variant="outlined"
          sx={{
                marginBottom: '16px',
                marginTop: '16px',
                height: '70px',
                  '& .MuiInputLabel-root': {
                    color: THEME_COLORS.black
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: THEME_COLORS.yellow
                  },
                }}
        />

        <div>
          {loading ? (
            <CircularProgress size={24} sx={{ margin: '10px 0' }} />
          ) : (
            displayedText && (
              <div className={classes.aiResponse} >
                <Typography>{displayedText}</Typography>
              </div>
            )
          )}
        </div>
      </DialogContent>
      <DialogActions>
        {showUseButton && (
                  <IconButton onClick={handleUse} sx={{ color: '#FFBF00', fontSize: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                     <Typography sx={{fontSize: '14px'}}>USE THIS {scriptPart.toUpperCase()} </Typography>
                    <CheckIcon />
                  </IconButton>
                )}
      </DialogActions>
    </Dialog>
  );
};

export default OpenAIModal;