import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CreateVideoPageDialog from '../../components/CreateVideoPageDialog';// assuming you have this component
import { getAllProjects, getProjectById } from '../../client/api';
import { useProjectContext } from '../../context/ProjectContext';
import { useUserContext } from '../../context/UserContext';

const ProjectDashboard: React.FC = () => {
  const { projectId } = useParams(); // get the project id from the route
  const navigate = useNavigate();
  const {currentProject, setCurrentProject, setProjects} = useProjectContext();
  const { currentWorkspace } = useUserContext();
  const [loadingCreateVideoPage, setLoadingCreateVideoPage] = useState(false);
  const [openNewVideoPageDialog, setOpenNewVideoPageDialog] = useState(false);

  useEffect(() => {
    // Fetch the project details using projectId when the component mounts
    const fetchProject = async () => {
      if (projectId) {
        try {
          const project = await getProjectById(projectId);
          setCurrentProject(project);
        } catch (error) {
          console.error('Error fetching project:', error);
        }
      }
    };

    fetchProject();
  }, [projectId]);

  const handleOpenVideoPageDialog = () => {
    setOpenNewVideoPageDialog(true);
  };

  const handleCloseVideoPageDialog = () => {
    setOpenNewVideoPageDialog(false);
  };

  const onVideoPageCreated = async (newVideoPage: any) => {
    if(!currentWorkspace) return
    try {
      setLoadingCreateVideoPage(true);
      const updatedProject = await getProjectById(projectId!);
      setCurrentProject(updatedProject);
      const projectsResponse = await getAllProjects(1, 10,'', currentWorkspace._id);
      setProjects(projectsResponse.projects)
      // Assuming this updates some state to keep the list of projects up to date
      setOpenNewVideoPageDialog(false);
      navigate(`/projects/${projectId}/video/${newVideoPage._id}`);
    } catch (error) {
      console.error('Error updating project after video creation:', error);
    } finally {
      setLoadingCreateVideoPage(false);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      {/* Display the project name */}
      <h1>{currentProject ? currentProject.name : 'Loading...'}</h1>

      {/* Centered Button to Create New Videos */}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px', height: '400px' }}>
        <button
          style={{
            backgroundColor: '#FFC107',
            border: 'none',
            borderRadius: '5px',
            padding: '15px 30px',
            fontWeight: 'bold',
            fontSize: '14px',
            color: '#000',
            cursor: 'pointer',
            fontFamily: 'Lexend, sans-serif',
            height: '50px'
          }}
          onClick={handleOpenVideoPageDialog}
        >
          + CREATE NEW VIDEOS
        </button>
      </div>

      {/* Create Video Page Dialog */}
      {openNewVideoPageDialog && currentProject && projectId && (
        <CreateVideoPageDialog
          open={openNewVideoPageDialog}
          onClose={handleCloseVideoPageDialog}
          projectId={projectId}
          onVideoPageCreated={onVideoPageCreated}
          loading={loadingCreateVideoPage}
        />
      )}
    </div>
  );
};

export default ProjectDashboard;
