import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '10px',
    }
});

export default useStyles