import React from 'react';

interface LightningArrowIconProps {
  width?: number;
  height?: number;
}

const LightningArrowIcon: React.FC<LightningArrowIconProps> = ({ width = 29, height = 22 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.8333 0.00452908C28.8896 3.21112 28.9437 6.34071 29 9.55636C28.804 9.43407 28.6688 9.36161 28.5472 9.27102C27.806 8.72753 27.0739 8.17272 26.3282 7.63603C25.8641 7.30088 25.9723 7.26917 25.5487 7.6949C23.4176 9.84848 21.291 12.0066 19.1621 14.1624C18.662 14.6697 18.1574 15.1747 17.628 15.7068C17.5198 15.5914 17.423 15.4962 17.3329 15.3943C16.461 14.4319 15.5915 13.4672 14.7196 12.5048C14.4088 12.1606 14.3885 12.1651 14.0844 12.5818C12.7102 14.4749 11.3382 16.3681 9.96178 18.259C9.70947 18.6055 9.70722 18.6055 9.32875 18.3767C8.21813 17.7064 7.10075 17.0475 6.00365 16.3568C5.69727 16.1643 5.51705 16.2073 5.28952 16.4541C4.31857 17.5026 3.33636 18.5398 2.35866 19.5792C1.58821 20.399 0.817758 21.2165 0 22C0.355939 21.4814 0.709625 20.9628 1.07007 20.4465C2.61322 18.2363 4.15863 16.0261 5.70178 13.816C5.94733 13.465 5.94508 13.465 6.27849 13.7593C7.24043 14.6153 8.20236 15.4713 9.1643 16.3273C9.46166 16.59 9.4887 16.581 9.68694 16.2503C11.1648 13.7775 12.6471 11.3068 14.1272 8.83623C14.1632 8.77509 14.224 8.72754 14.3006 8.64148C14.6633 9.0876 15.0125 9.51107 15.3572 9.93906C15.997 10.7294 16.6345 11.522 17.2765 12.3123C17.4883 12.5727 17.5491 12.5682 17.7249 12.2964C19.2432 9.93906 20.7638 7.58168 22.28 5.22203C22.5345 4.828 22.5255 4.828 22.1583 4.56078C21.4329 4.03314 20.7098 3.50098 19.9866 2.96881C19.9191 2.91899 19.8605 2.85558 19.7298 2.73556C22.7778 1.81843 25.7673 0.921668 28.831 0L28.8333 0.00452908Z"
        fill="black"
      />
    </svg>
  );
};

export default LightningArrowIcon;
