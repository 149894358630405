export const THEME_COLORS = {
  yellow: '#FFBF00',
  lightYellow: '#fdeec0',
  lightYellow2: '#fad666',
  darkYellow: '#d5a102',
  gray: '#D9D9D9',
  darkGray: '#8a8787',
  darkGray2: '#636363',
  lightGray: '#f3f3f3',
  black: '#000',
  white: '#fff',
} as const;