import React from 'react';
import { Alert, Snackbar } from '@mui/material';

type NotificationProps = {
  message: string;
  type: 'info' | 'error' | 'warning' | 'success';
  onClose: () => void;
};

export const Notification: React.FC<NotificationProps> = ({ message, type, onClose }) => {
  return (
    <Snackbar open autoHideDuration={5000} onClose={onClose}>
      <Alert onClose={onClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};
