import React, { useEffect } from 'react';
import { Box, Grid, Typography, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { checkBuilderAccess, checkLetsLaunchAccess } from '../../client/api';

const AppSelection: React.FC = () => {
  const navigate = useNavigate();

useEffect(() => {
    const checkAccess = async () => {
      try {
        // Get `authInfo` from the cookie
        const authInfoCookie = Cookies.get('authInfo');
        if (!authInfoCookie) {
          console.error('No authInfo found in cookies.');
          return;
        }

        const { organizationKey } = JSON.parse(authInfoCookie);

        // Check access for LetsLaunch and Builder
        const hasLetsLaunchAccess = await checkLetsLaunchAccess(organizationKey);
        const hasBuilderAccess = await checkBuilderAccess(organizationKey);

        // Redirect based on access
        if (hasBuilderAccess && !hasLetsLaunchAccess) {
          const builderUrl = process.env.REACT_APP_BUILDER_URL || '/'; // Fallback to root if URL is undefined
          window.location.href = builderUrl;
        } else if (!hasBuilderAccess && hasLetsLaunchAccess) {
          navigate('/'); // Navigate to the main application
        }
        // If both are accessible, do nothing
      } catch (error) {
        console.error('Error checking access:', error);
      }
    };

    checkAccess();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Choose Your Application
      </Typography>
      <Grid container spacing={4} sx={{ mt: 2, maxWidth: 600 }}>
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 2,
              cursor: 'pointer',
              '&:hover': {
                boxShadow: 6,
              },
            }}
            onClick={() => navigate('/')}
          >
            <img
              src="/logo512.png"
              alt="LetsLaunch Logo"
              style={{ width: 128, height: 128 }}
            />
            <Typography variant="h6" sx={{ mt: 2 }}>
              LetsLaunch
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 2,
              cursor: 'pointer',
              '&:hover': {
                boxShadow: 6,
              },
            }}
            onClick={() => { const builderUrl = process.env.REACT_APP_BUILDER_URL || '/'; // Fallback to root if URL is undefined
      window.location.href = builderUrl;}}
          >
            <img
              src="/builder-logo-512.png"
              alt="Landing Page Builder Logo"
              style={{ width: 128, height: 128 }}
            />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Landing Page Builder
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AppSelection;
