import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  inputLabel: {
    color: '#878787 !important', // Label color when not focused
    '&.Mui-focused': {
      color: '#FFBF0091 !important', // Optional: Keep black when focused
    },
  },
  textField: {
    marginBottom: '12px !important', // Margin for TextField
  },
  mainBox: {
    
  }
}));

export default useStyles;
