import { makeStyles } from '@mui/styles';
import { THEME_COLORS } from '../../context/constants';

const useStyles = makeStyles({
  infoBox: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    marginTop: '10px',
  },
  badge: {
    backgroundColor: THEME_COLORS.yellow,
    color: THEME_COLORS.black,
    borderRadius: '5px',
    padding: '4px',
    fontSize: '12px'
  }
});

export default useStyles;