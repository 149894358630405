import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tab,
  Tabs,
  TextField,
  Button,
  Typography,
  Tooltip,
  IconButton,
  CircularProgress,
  Box,
  Divider,
} from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import { User } from '../../context/types';
import { useNotification } from '../../context/NotificationContext';
import copy from 'copy-to-clipboard';
import useStyles from './styles';
import { THEME_COLORS } from '../../context/constants';
import { USER_ROLE } from '../../utils/utils';
import { giveUserAppFullAccess } from '../../client/api';
import { useUserContext } from '../../context/UserContext';

interface AccountDialogProps {
  open: boolean;
  onClose: () => void;
  user: User;
}

const AccountDialog: React.FC<AccountDialogProps> = ({ open, onClose, user } : AccountDialogProps) => {
  const [tabValue, setTabValue] = useState<number>(0);
  const [name, setName] = useState<string>(user.name);
  const [accessKey, setAccessKey] = useState<string>(''); // New state for access key
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { addNotification } = useNotification();
  const { setUser } = useUserContext();
  const classes = useStyles();

  useEffect(() => {
    setName(user.name);
  }, [user]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleSaveName = async () => {
    setIsLoading(true);
    try {
      // Placeholder for save name API call
      // await updateUserName(user._id, name);
      addNotification('Name updated!', 'success');
    } catch (error) {
      addNotification('Failed to update name', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyId = () => {
    copy(user._id);
    addNotification('User ID copied to clipboard', 'success');
  };

  const handleRequestFullAccess = async () => {
    setIsLoading(true);
    try {
      const response = await giveUserAppFullAccess(user._id, accessKey); // Call the API method with user ID and access key
      if(response.user){
        setUser(response.user)
      }
      addNotification('User given full access!', 'success');
    } catch (error: any) {
      const message = error?.response?.data?.message;
      const displayMessage = message ? `: ${message}` : ''
      addNotification('Failed to give full access'+displayMessage, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth>
      <DialogTitle>ACCOUNT SETTINGS</DialogTitle>
      <DialogContent>
        <Tabs value={tabValue} onChange={handleTabChange} sx={{
            color: THEME_COLORS.yellow, 
            '& .MuiTab-root': { color: 'black' }, // Inactive tab color
            '& .Mui-selected': { color: THEME_COLORS.yellow } // Active tab color
        }}>
          <Tab label="Account" />
          <Tab label="Workspaces" sx={{opacity: 0}} disabled/>
        </Tabs>
        {tabValue === 0 && (
          <Box>
            <Box display="flex" alignItems="center" mb={2} mt={2}>
                <Typography variant="body1" sx={{marginRight: '5px'}}>User ID: </Typography>
                <Typography className={classes.badge} >{user._id}</Typography>
                <Tooltip title="Copy ID">
                  <IconButton onClick={handleCopyId} size="small">
                    <ContentCopy />
                  </IconButton>
                </Tooltip>
            </Box>
            <Box className={classes.infoBox}>
              <Typography variant="body1">Email:</Typography>
              <Typography className={classes.badge}>{user.email}</Typography>
            </Box>
            <Box className={classes.infoBox}>
              <TextField
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled
                margin="normal"
                sx={{
                  '& .MuiInputLabel-root': {
                    color: THEME_COLORS.black
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: THEME_COLORS.yellow
                  },
                }}
              />
            </Box>
            <Divider sx={{marginTop: '20px'}}/>
            <Box className={classes.infoBox} sx={{marginTop: '20px !important'}}>
              <Typography variant="body1">Full Access to LetsLaunch:</Typography>
              <Tooltip title={user.role === USER_ROLE.admin ? "You have full access to LetsLaunch!" : "Get a full access key and be able to generate unlimited videos and create workspaces with full access"}>
                <Typography variant="body2" className={classes.badge}>
                  {user.role === USER_ROLE.admin ? 'YES' : 'NO'}
                </Typography>
              </Tooltip>
            </Box>
            {user.role !== USER_ROLE.admin && (
            <Box className={classes.infoBox} gap={1} mt={1}>
              <TextField
                label="Full Access Key"
                type="password"
                value={accessKey}
                onChange={(e) => setAccessKey(e.target.value)}
                sx={{
                  '& .MuiInputBase-root': {
                    height: '40px',
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                  },
                  '& .MuiInputLabel-root': {
                    color: THEME_COLORS.black,
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: THEME_COLORS.yellow,
                  },
                }}
                InputLabelProps={{
                  shrink: true, // Ensures the label is always outside the field
                }}
              />
              <Button
                variant="outlined"
                onClick={handleRequestFullAccess}
                disabled={isLoading}
                sx={{
                  height: '40px',
                  lineHeight: '40px', // Align text vertically within the button
                }}
              >
                Request Full Access
                {isLoading && <CircularProgress size={20} sx={{ ml: 1 }} />}
              </Button>
            </Box>
            )}
           
          </Box>
        )}
        {/* Workspaces Tab will be implemented later */}
      </DialogContent>
      <DialogActions>
        {tabValue === 0 && (
          <Button onClick={handleSaveName} disabled={isLoading}>
            Save {isLoading && <CircularProgress size={24} />}
          </Button>
        )}
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccountDialog;
