import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';
import { useUserContext } from '../../context/UserContext';
import { checkBuilderAccess, checkLetsLaunchAccess, getUserById, loginUser } from '../../client/api'; // API call for login
import LogoContainer from '../../components/LogoContainer';
import Logo from '../../components/Logo';
import useStyles from './styles'; // Import the custom styles
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

const Login: React.FC = () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { user, setUser, setCurrentWorkspace } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    // Check for `app=builder` query parameter in the URL
    const params = new URLSearchParams(location.search);
    if (params.get('app')) {
      sessionStorage.setItem('redirectToBuilder', 'true');
    } else {
      if(sessionStorage.getItem('redirectToBuilder')){
        sessionStorage.removeItem('redirectToBuilder');
      }
    }
  }, [location]);

    useEffect(() => {
    const checkUserFromCookie = async () => {
      try {
        const authInfo = Cookies.get('authInfo');
        if (!authInfo) return; // No cookie found

        const { userId } = JSON.parse(authInfo);

        // Check if user ID exists
        if (userId) {
          const userData = await getUserById(userId);

          if (userData) {
            // User exists, redirect to homepage
            setUser(userData);
            localStorage.setItem('userId', userId)

            const redirectToBuilder = sessionStorage.getItem('redirectToBuilder')

            if(userData.organization.letsLaunchAccess && !redirectToBuilder){
              navigate('/');
            } else {
              if(redirectToBuilder){
               sessionStorage.removeItem('redirectToBuilder')
              }
              const builderUrl = process.env.REACT_APP_BUILDER_URL || '/'; // Fallback to root if URL is undefined
              window.location.href = builderUrl;
            }
          }
        }
      } catch (error) {
        console.error('Error checking user from cookie:', error);
      }
    };

    // Check for `app=builder` query parameter in the URL
    const params = new URLSearchParams(location.search);
    if (params.get('app')) {
      sessionStorage.setItem('redirectToBuilder', 'true');
    }

    // Check user from cookie
    checkUserFromCookie();
  }, []);

  const handleLogin = async () => {
    try {
      const data = await loginUser(email, password);
      setUser(data.user); // Assume the response contains the user object
      setCurrentWorkspace(data.user.ownedWorkspaces[0]);

      // Store user details in a cookie accessible by all subdomains
      const cookieData = {
        userId: data.user._id,
        organizationKey: data.user.organization.organizationKey,
        letsLaunchAccess: data.user.organization.letsLaunchAccess,
      };
      Cookies.set('authInfo', JSON.stringify(cookieData), {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        path: '/',
        secure: true,
        sameSite: 'strict',
      });
      localStorage.setItem('iserId', data.user._id)
      const redirectToBuilder = !!sessionStorage.getItem('redirectToBuilder')

      let hasLetsLaunchAccess = false
      let hasBuilderAccess = redirectToBuilder
      if (data.user.organization){
        // TO DO: CHECK HOW THE DATA IS RETURNED HERE
        hasLetsLaunchAccess = await checkLetsLaunchAccess(data.user.organization.organizationKey)
        hasBuilderAccess = await checkBuilderAccess(data.user.organization.organizationKey)
      }

      console.log(`letslaunch ${hasLetsLaunchAccess}`)
      console.log(`builder ${hasBuilderAccess}`)
      // Check for redirect to builder
      if (redirectToBuilder || (!hasLetsLaunchAccess && hasBuilderAccess)) {
        console.log('redirect')
        sessionStorage.removeItem('redirectToBuilder');
        const builderUrl = process.env.REACT_APP_BUILDER_URL || '/'; // Fallback to root if URL is undefined
        window.location.href = builderUrl;
        return
      } else if(hasLetsLaunchAccess && hasBuilderAccess){
        navigate('/app-selection')
      } else {
        // TO DO: COMPLETE THE REST OF THE SCENARIOS HERE
        navigate('/'); // Redirect to homepage
      }
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', padding: 2 }}>
      <LogoContainer>
        <Logo />
        <Typography variant="h6" sx={{ color: '#000', fontWeight: 'bold' }}>
          LetsLaunch
        </Typography>
      </LogoContainer>
      <Typography variant="h5">Login</Typography>
      <br />
      {/* Box for input fields */}
      <Box sx={{ marginBottom: 2 }}>
        <TextField
          fullWidth
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputLabelProps={{
            shrink: true, // Keep the label always outside
            className: classes.inputLabel, // Use the custom label style
          }}
          className={classes.textField}
        />
        <TextField
          fullWidth
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputLabelProps={{
            shrink: true, // Keep the label always outside
            className: classes.inputLabel, // Use the custom label style
          }}
          className={classes.textField}
        />
      </Box>

      {/* Box for buttons */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Button variant="contained" color="primary" onClick={handleLogin}>
          Login
        </Button>
        <Button variant="text" href="/signup">
          Sign Up
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
