import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Container,
} from '@mui/material';
import { getWorkspaceById, addMemberToWorkspace, getUserById, checkIfInvitationIsValid, addAdminToWorkspace } from '../../client/api'; 
import { User, Workspace } from '../../context/types';
import { USER_WORKSPACE_ROLE } from '../../utils/utils';

const InvitationPage = () => {
  const { workspaceId, encodedInvitationKey } = useParams();
  const [user, setUser] = useState<User>()
  const [loading, setLoading] = useState(true);
  const [workspace, setWorkspace] = useState<Workspace>();
  const [invitationValid, setInvitationValid] = useState(true);
  const [invitationRole, setInvitationRole] = useState(USER_WORKSPACE_ROLE.member);
  const navigate = useNavigate();

  useEffect(() => {
    const validateInvitation = async () => {
      if(!workspaceId || !encodedInvitationKey){
        setInvitationValid(false)
        return
      }
      try {
        const { isValid, role } = await checkIfInvitationIsValid(workspaceId, encodedInvitationKey);
        if (isValid) {
          const data = await getWorkspaceById(workspaceId);
          setWorkspace(data);
          setInvitationRole(role);
          localStorage.setItem('userRole', role)
        } else {
          setInvitationValid(false);
        }
      } catch (error) {
        console.error('Error validating invitation:', error);
        setInvitationValid(false); // If there's an error, assume invitation is invalid
      } finally {
        setLoading(false);
      }
    };
    const fetchUser = async () => {
      const userId = localStorage.getItem('userId'); // Get userId from local storage
      if (userId) {
        try {
          const userData = await getUserById(userId); // Fetch user from API
          setUser(userData); // Set the user in the context
        } catch (error) {
          console.error('Failed to fetch user:', error);
        }
      }
    };

    fetchUser();
    validateInvitation();
  }, [workspaceId, encodedInvitationKey]);

  const handleJoinWorkspace = async () => {
    if (user && workspaceId) {
      try {
        const userId = localStorage.getItem('userId');
        const userRole = localStorage.getItem('userRole');
        if(!userId) return
        if(userRole === USER_WORKSPACE_ROLE.admin || invitationRole === USER_WORKSPACE_ROLE.admin){
           await addAdminToWorkspace(workspaceId, userId);
        } else {
           await addMemberToWorkspace(workspaceId, userId);
        }
        navigate('/'); // Redirect to homepage after joining
      } catch (error) {
        console.error('Error adding member to workspace:', error);
      }
    }
  };

  const handleLoginSignup = () => {
    localStorage.setItem('invitationUrl', window.location.pathname);
    navigate('/login'); // Redirect to login/signup page
  };

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

 return (
    <Container>
      <Box textAlign="center" marginTop={5}>
        {invitationValid ? (
          <>
            <Typography variant="h4">
              You’ve been invited to {workspace?.name ?? 'a LetsLaunch workspace'}
            </Typography>
            <Typography variant="body1" marginY={2}>
              {user?._id 
                ? "Click the button below to join the workspace!" 
                : `But first, you need to login/signup on your LetsLaunch account.`}
            </Typography>
            {user?._id ? (
              <Button variant="contained" color="primary" onClick={handleJoinWorkspace}>
                Join Workspace
              </Button>
            ) : (
              <Button variant="contained" color="primary" onClick={handleLoginSignup}>
                Login/Signup
              </Button>
            )}
          </>
        ) : (
          <Typography variant="h4" color="error">
            This invitation expired, please ask {workspace?.owner?.email ?? ''} for a new invite.
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default InvitationPage;
