import React from 'react';

interface ScriptSvgProps {
  width?: number;
  height?: number;
}

const ScriptSvg: React.FC<ScriptSvgProps> = ({ width = 19, height = 23 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.00857218 11.6015C0.00984072 8.86015 0.039017 6.11881 0.000960973 3.3787C-0.0320209 1.09404 0.777304 -0.0598824 3.46279 0.00239304C5.92882 0.0597841 8.39866 -0.0110389 10.8647 0.04391C11.3543 0.0548998 11.9556 0.267369 12.3032 0.589736C14.3544 2.4873 16.3676 4.42273 18.3351 6.40089C18.6928 6.75989 18.9529 7.36921 18.9592 7.86741C19.01 11.8628 18.9694 15.8582 18.9998 19.8548C19.015 21.9307 18.1474 22.9893 15.6953 22.9735C11.3772 22.9454 7.05655 22.8904 2.741 22.9991C1.14137 23.0394 0.019989 21.6974 0.0123778 20.3213C-0.00411317 17.4151 0.00730365 14.5089 0.00857218 11.6027V11.6015ZM2.16001 11.4708C2.16001 14.2439 2.17777 17.017 2.14859 19.7889C2.13971 20.585 2.41245 20.9086 3.27505 20.9025C7.42062 20.8744 11.5662 20.8781 15.7105 20.9025C16.5287 20.9074 16.889 20.6595 16.8826 19.8255C16.8547 16.194 16.8585 12.5637 16.8788 8.9322C16.8826 8.18001 16.6099 7.84787 15.8006 7.86863C14.4661 7.90404 13.1278 7.85276 11.7933 7.88573C11.074 7.90404 10.8279 7.62319 10.8469 6.94915C10.8812 5.6951 10.8152 4.4386 10.8698 3.18699C10.9078 2.30536 10.5285 2.03184 9.65831 2.04771C7.56904 2.08557 5.47849 2.09411 3.38922 2.04527C2.4467 2.02329 2.12576 2.34199 2.14225 3.2517C2.18918 5.9906 2.15874 8.73072 2.15874 11.4708H2.16001Z"
        fill="#636363"
      />
      <path
        d="M9.54795 11.6296C10.7087 11.6284 11.8719 11.6577 13.0313 11.6174C13.8052 11.5905 14.2288 11.7407 14.2187 12.6296C14.2098 13.4612 13.9041 13.7054 13.0757 13.6908C10.6858 13.6505 8.29337 13.6505 5.90345 13.6859C5.11062 13.6981 4.81378 13.4527 4.8252 12.6712C4.83535 11.9422 5.01167 11.5783 5.85905 11.6161C7.08573 11.6699 8.31747 11.6296 9.54668 11.6284L9.54795 11.6296Z"
        fill="#636363"
      />
      <path
        d="M9.55303 16.2612C10.8178 16.2612 12.0825 16.2892 13.3459 16.2489C14.1616 16.2233 14.2073 16.6788 14.1971 17.2612C14.1883 17.8156 14.2136 18.3199 13.3802 18.3126C10.8178 18.2894 8.25405 18.2833 5.69161 18.3138C4.77953 18.3248 4.8696 17.7692 4.83788 17.1977C4.80363 16.5444 5.01675 16.227 5.76011 16.2502C7.02357 16.2905 8.2883 16.2612 9.55303 16.2599V16.2612Z"
        fill="#636363"
      />
    </svg>
  );
};

export default ScriptSvg;
