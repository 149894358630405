// components/Logo.tsx
import React from 'react';
import { Box, styled } from '@mui/material';

const StyledLogo = styled(Box)(({ theme }) => ({
  width: 30,
  height: 30,
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  marginRight: theme.spacing(1),
  color: '#000',
  fontWeight: 'bold',
  fontSize: 18,
}));

const Logo: React.FC = () => {
  return <StyledLogo>L</StyledLogo>;
};

export default Logo