// ProjectContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';
import { Project, VideoPage, Actor } from './types';
import { getActors } from '../client/api'; // Assuming you have the getActors API call function

interface ProjectContextType {
  projects: Project[];
  currentProject: Project | null;
  currentVideoPage: VideoPage | null;
  avatars: Actor[] | null; // Add avatars to the context type
  setProjects: React.Dispatch<React.SetStateAction<Project[]>>;
  setCurrentProject: React.Dispatch<React.SetStateAction<Project | null>>;
  setCurrentVideoPage: React.Dispatch<React.SetStateAction<VideoPage | null>>;
  fetchAvatars: () => Promise<void>; // Add a method to fetch avatars
}

const ProjectContext = createContext<ProjectContextType | undefined>(undefined);

export const useProjectContext = () => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error('useProjectContext must be used within a ProjectProvider');
  }
  return context;
};

interface ProjectProviderProps {
  children: ReactNode;
}

export const ProjectProvider = ({ children }: ProjectProviderProps) => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [currentProject, setCurrentProject] = useState<Project | null>(null);
  const [currentVideoPage, setCurrentVideoPage] = useState<VideoPage | null>(null);
  const [avatars, setAvatars] = useState<Actor[] | null>(null); // State for avatars

  // Function to fetch avatars and set state if not already set
  const fetchAvatars = async () => {
      try {
        const data = await getActors(); // Call the backend to get avatars
        console.log(data)
        setAvatars(data.data.avatars); // Set avatars in state
      } catch (error) {
        console.error("Error fetching avatars: ", error);
      }
  };

  return (
    <ProjectContext.Provider
      value={{
        projects,
        currentProject,
        currentVideoPage,
        avatars,
        setProjects,
        setCurrentProject,
        setCurrentVideoPage,
        fetchAvatars, // Expose the fetch method to the context
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
