import { makeStyles } from '@mui/styles';
import { THEME_COLORS } from '../../context/constants';

const useStyles = makeStyles({
 typingAnimation: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    borderRight: '2px solid transparent',
    animation: '$typing 2s steps(30) 1s forwards',
  },
  '@keyframes typing': {
    from: {
      width: 0,
    },
    to: {
      width: '100%',
    },
  },
  aiResponse: { whiteSpace: 'pre-line', wordWrap: 'break-word', 
    display: 'flex', 
    backgroundColor: THEME_COLORS.gray, 
    borderRadius: '8px', 
    padding: '8px' 
}
});

export default useStyles;
