import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { updateVideoPage, getProjectById, updateVideoPageActors, registerActor, getActorByAvatarId, generateVideo, getActors, getVideoPageById, removeVideoPageFromProject, deleteVideoPage, getAllProjects, updateScript, updateGeneratedVideoStatus, removeGeneratedVideo, generateVideosMultipleActors, getSuggestion } from '../../client/api';
import useStyles from './styles'; // Import the styles
import { useProjectContext } from '../../context/ProjectContext';
import { Actor, GeneratedVideo, Script, VideoPage as VideoPageType } from '../../context/types'; // Import the VideoPage type
import { THEME_COLORS } from '../../context/constants';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ScriptSvg from '../../icons/ScriptSvg';
import PlayIconSvg from '../../icons/PlayIconSvg';
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, Menu, MenuItem, TextField, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save'; 
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ActorsDialog from '../../components/ActorsDialog'; // Import ActorsDialog component
import LightningArrowIcon from '../../icons/LightningArrowIcon';
import { useUserContext } from '../../context/UserContext';
import { useNotification } from '../../context/NotificationContext';
import VideoDialog from '../VideoDialog';
import { Download, PlayArrow } from '@mui/icons-material';
import { canGenerateVideos } from '../../utils/utils';
import OpenAIModal from '../OpenaiModal';

const VideoPage = () => {
  const classes = useStyles(); // Initialize the styles
  const { projectId, videoPageId } = useParams();
  const { currentProject, setCurrentProject, projects, setProjects } = useProjectContext();
  const { user, currentWorkspace } = useUserContext();
  const { addNotification } = useNotification(); // Use the notification hook
  const [videoPage, setVideoPage] = useState<VideoPageType | null>(null); // Use the correct type
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentScript, setCurrentScript] = useState<Script | null>(null);
  const [headline, setHeadline] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [cta, setCta] = useState<string>('');
  const [isActorsDialogOpen, setIsActorsDialogOpen] = useState(false); // State for managing dialog
  const [isLoadingAvatars, setIsLoadingAvatars] = useState(false); // Loading state for avatars
  const [actors, setActors] = useState<Actor[]>([]);
  const [generatedVideos, setGeneratedVideos] = useState<GeneratedVideo[]>([]);
  const [showGeneratedVideos, setShowGeneratedVideos] = useState(false);
  const [selectedVideoActor, setSelectedVideoActor] = useState(actors[0] || null);
  const [selectedVideoActors, setSelectedVideoActors] = useState<string[]>([]);
  const [isTestVideo, setIsTestVideo] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [updatedName, setUpdatedName] = useState(videoPage?.name || ''); 
  const [isLoading, setIsLoading] = useState(false);
  const [videoBeingRefreshed, setVideoBeingRefreshed] = useState<string | null>(null);
  const [videoBeingDeleted, setVideoBeingDeleted] = useState<string | null>(null);
  const [generatingVideos, setGeneratingVideos] = useState(false);
  const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const navigate = useNavigate(); // Initialize navigate
  const [openAIModal, setOpenAIModal] = useState<boolean>(false);
  const [currentPrompt, setCurrentPrompt] = useState<string>('');
  const [reloadPrompt, setReloadPrompt] = useState<string>('');
  const [scriptPart, setScriptPart] = useState<'headline' | 'body' | 'cta'>('headline')


  useEffect(() => {
    setVideoPage(null)
    const fetchProjectAndSetPage = async () => {
      setActors([])
      if (projectId) {

        try {
          // Fetch the project by projectId if currentProject is not set
          const project = await getProjectById(projectId);
          setCurrentProject(project);
          // Find the video page once the project is fetched
          const page = project.videoPages.find((vp: VideoPageType) => vp._id === videoPageId);
          if (page) {
            setVideoPage(page);
            setCurrentScript(page.script?.[0])
            setGeneratedVideos(page?.generatedVideos || [])
            setActors(page.actors || [])
            setSelectedVideoActors((page.actors || []).map((actor: Actor) => actor.avatar_states[0].id));
            setHeadline(page.script?.headline || '');
            setBody(page.script?.body || '');
            setCta(page.script?.cta || '');
          }
        } catch (error) {
          console.error('Failed to fetch project:', error);
        }
      }
    };

    fetchProjectAndSetPage();
  }, [projectId, videoPageId, setCurrentProject]);

  useEffect(() => {
    if (actors.length > 0 && actors[0].avatar_states[0]) {
      setSelectedVideoActor(actors[0]);
    }
  }, [actors]);


  // Function to ensure all actors are registered and return their IDs
  const ensureActorsRegistered = async (selectedActors: Actor[]) => {
    const actorIds: string[] = [];

    for (const actor of selectedActors) {
      try {
        // Call the backend to register the actor
        const registeredActor = await registerActor({
          avatar_id: actor.avatar_id,  // Include avatar_id from Heygen
          avatar_states: actor.avatar_states,
          name: actor.name,
          gender: actor.gender,
        });

        // Push the actor's ID to the array
        actorIds.push(registeredActor._id);
      } catch (error: any) {
        console.error('Error registering actor:', error?.message);
        // Handle cases where the actor already exists or there's an error
        if (error.response && error.response.status === 400) {
          // If actor already exists, manually extract the ID from the response
          const existingActor = await getActorByAvatarId(actor.avatar_states[0].id); // Assume you have this function
          actorIds.push(existingActor._id);
        } else {
          throw error; // Re-throw if it's an unexpected error
        }
      }
    }

    return actorIds; // Return all registered actor IDs
  };


  const handleDoneSelectingAvatars = async (selectedActors: Actor[]) => {
    if (!videoPage) return;

    setIsLoadingAvatars(true); // Start loading

    try {
      // Step 1: Ensure all actors are registered and get their IDs
      const registeredActorIds = await ensureActorsRegistered(selectedActors);

      // Step 2: Update the video page with the registered actor IDs
      await updateVideoPageActors(videoPageId || '', registeredActorIds, user?.email || '');

      // Step 3: Fetch updated project data to reflect the new actors
      const updatedProject = await getProjectById(projectId || currentProject?._id || '');
      setCurrentProject(updatedProject); // Update the current project state with the new actors

      // Set the new actor list on the video page
      setVideoPage((prevPage) => {
        if (prevPage) {
          return { ...prevPage, actors: selectedActors }; // Assuming you're keeping selectedActors for display
        }
        return prevPage;
      });

      setSelectedVideoActors([])
    } catch (error) {
      console.error("Failed to update actors:", error);
    } finally {
      setIsLoadingAvatars(false); // End loading
    }
  };

  const handleDeleteVideoPage = async () => {
    if (!videoPageId || !projectId || !currentWorkspace) return;

    try {
      // Call the API to remove the video page from the project
      await removeVideoPageFromProject(projectId, videoPageId);
      // Call the API to delete the video page
      await deleteVideoPage(videoPageId);
      // Update projects
      const projectsResponse = await getAllProjects(1, 10, '', currentWorkspace._id);
      setProjects(projectsResponse.projects);
      // Redirect to the home page
      navigate('/');
      // Show success notification
      addNotification('Video page deleted successfully!', 'success');
    } catch (error: any) {
      console.error('Failed to delete video page:', error);
      // Show error notification
      addNotification(`Failed to delete video page - ${error?.response?.data?.message}`, 'error');
    }
  };

  const handleEditDialogOpen = () => {
    setUpdatedName(videoPage?.name || ''); // Reset to current name
    setIsEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setIsEditDialogOpen(false);
  };

  const handleUpdateVideoPageName = async () => {
    if (!videoPageId) return;
    try {
      // Update the video page on the server
      await updateVideoPage(videoPageId, { name: updatedName }, user?.email || '');

      // Update the local video page state
      setVideoPage((prevPage) => prevPage ? { ...prevPage, name: updatedName } : prevPage);

      // Find the index of the current project
      const projectIndex = projects.findIndex(project => project._id === currentProject?._id);
      
      if (projectIndex !== -1) {
        // Update the video page name in the current project's video pages
        const updatedProjects = [...projects];
        const videoPageIndex = updatedProjects[projectIndex].videoPages.findIndex(videoPage => videoPage._id === videoPageId);

        if (videoPageIndex !== -1) {
          updatedProjects[projectIndex].videoPages[videoPageIndex] = {
            ...updatedProjects[projectIndex].videoPages[videoPageIndex],
            name: updatedName,
          };
          
          // Update the projects state
          setProjects(updatedProjects);
          // Update the current project in context if necessary
          setCurrentProject(updatedProjects[projectIndex]);
        }
      }

      addNotification('Video page name updated successfully!', 'success');
      handleEditDialogClose();
    } catch (error: any) {
      console.error('Failed to update video page name:', error);
      addNotification(`Failed to update video page name - ${error?.message}`, 'error');
    }
  };

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleScriptSelect = (script: Script) => {
    setCurrentScript(script);
    setHeadline(script?.headline || '');
    setBody(script?.body || '');
    setCta(script?.cta || '');
    handleMenuClose();
  };

  const handleScriptSave = async () => {
    if (!currentScript || !currentScript.headline || !user || !videoPage) return;

    // Find the script to update in videoPage.script array
    const updatedScripts = videoPage.script.map((script) =>
      script._id === currentScript._id ? currentScript : script
    );

    // Create a new videoPage object with the updated scripts
    const updatedVideoPage = {
      script: updatedScripts,
    };

    try {
      setIsLoading(true);
      // Send the updated videoPage object to the updateVideoPage function
      await updateVideoPage(videoPageId || '', updatedVideoPage, user.email);
      addNotification('Script updated successfully!', 'success');
    } catch (error) {
      console.error('Failed to update script:', error);
      addNotification('Failed to update script', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddNewScript = async (isCloning = false) => {
    if (!videoPage || !user) return;

    try {
      // Create a new script with default values (or empty ones)
      const newScript = {
        headline: isCloning ? currentScript?.headline : '',
        body: isCloning ? currentScript?.body : '',
        cta: isCloning ? currentScript?.cta : '',
      };

      // Update the videoPage by adding the new script to the existing ones
      const updatedScripts = [...(videoPage.script || []), newScript];
      
      const updatedData = { script: updatedScripts };
      
      // Update the video page with the new script array
      const savedVideoPage = await updateVideoPage(videoPageId || '', updatedData, user.email || '');
      if(savedVideoPage._id){
        setVideoPage(savedVideoPage);
        console.log(savedVideoPage.script?.[savedVideoPage.script.length -1])
        setCurrentScript(savedVideoPage.script?.[savedVideoPage.script.length -1]);
        const message = isCloning? 'Script cloned successfully' : 'New script added to the video page';
        addNotification(message, 'success');
      }
      // Fetch and update the current project to reflect the changes
      const updatedProject = await getProjectById(projectId || currentProject?._id || '');
      setCurrentProject(updatedProject);
    } catch (error: any){
      addNotification('Error adding new script', 'error')
    }

  };

  const setScriptHeadline = (value: string) => {
    if(!value || !currentScript) return
    setCurrentScript({...currentScript, headline: value})
    setHeadline(value)
  }

  const setScriptBody = (value: string) => {
    if(!value || !currentScript) return
    setCurrentScript({...currentScript, body: value})
    setBody(value)
  }

  const setScriptCta = (value: string) => {
    if(!value || !currentScript) return
    setCurrentScript({...currentScript, cta: value})
    setCta(value)
  }

  useEffect(() => {
    setActors(videoPage?.actors || [])
    setGeneratedVideos(videoPage?.generatedVideos || [])
  }, [videoPage])

  async function updateVideoStatusAndProject(videoId: string) {
    if(!videoPageId) return
    try {
      setVideoBeingRefreshed(videoId);
      // Call the proper backend method to update the video status
      await updateGeneratedVideoStatus(videoId);

      const updatedVideoPage: VideoPageType = await getVideoPageById(videoPageId);
      // Update the component state with the fetched video page data
      setVideoPage(updatedVideoPage);
      if(updatedVideoPage?.generatedVideos){
        setGeneratedVideos(updatedVideoPage.generatedVideos)
        setShowGeneratedVideos(true)
      }
    } catch (error) {
      console.error("Error updating video status and project:", error);
    } finally {
      setVideoBeingRefreshed(null);
    }
  }

  async function deleteGeneratedVideo(videoId: string){
    if(!videoPage) return
      try {
        setVideoBeingDeleted(videoId)
        const updatedVideoPage = await removeGeneratedVideo(videoPage?._id, videoId)
        setVideoPage(updatedVideoPage)
        addNotification('Video deleted', 'success')
      } catch (error) {
        addNotification('Error deleting video', 'error')
      } finally {
        setVideoBeingDeleted(null)
      }
  }

  const handleGenerateVideos = async () => {
    if (!videoPage ||!videoPageId || !videoPage.actors || videoPage.actors.length === 0 || !currentProject || !currentScript || !currentWorkspace) {
      console.error("No video page or actors found!");
      return;
    }
   // Assuming videoPage.actors[0] is the primary actor
    const primaryActor = videoPage.actors[0];
    const actorId = selectedVideoActors[0] || primaryActor.avatar_states[0].id; // change here
    const voiceId = primaryActor.avatar_states[0].default_voice.free.voice_id;
    const script = `${currentScript?.headline || 'Default Headline'}\n${currentScript?.body || 'Default Body'}\n${currentScript?.cta || 'Default CTA'}`;
    const scriptId = currentScript?._id;
    const dimension = {
      width: 1280,  // Default width
      height: 720,  // Default height
    };

    try {
      setGeneratingVideos(true)
      if(selectedVideoActors.length === 1){
        
      } else {

      }
      const voiceIds = (actors || []).reduce((map: Record<string, string>, actor: Actor) => {
          const actorId = actor.avatar_states[0].id;
          if (selectedVideoActors.includes(actorId)) {
            map[actorId] = actor.avatar_states[0].default_voice.free.voice_id;
          }
          return map;
      }, {});
      const videoIds = await generateVideosMultipleActors(currentWorkspace?._id, videoPage._id, selectedVideoActors, voiceIds, script, scriptId, dimension, isTestVideo, user?.email || '');
      console.log("Video generated successfully:", videoIds);

      await Promise.all(videoIds.map(async (videoId) => {
        await updateVideoStatusAndProject(videoId);
      }));

      // Fetch the updated project by ID

      // Now, refetch the video page data by using the correct functi

    } catch (error:any) {
      console.error("Failed to generate video:", error);
      const message = error?.response?.data?.message;
      const displayMessage = message ? `: ${message}` : ''
      addNotification('Failed to generate videos:'+displayMessage, 'error');
    } finally {
      setGeneratingVideos(false)
    }
  };

  const toggleView = () => {
    setShowGeneratedVideos((prevState) => !prevState);
  }

  const handleCloseVideoDialog = () => {
    setIsVideoDialogOpen(false)
    setVideoUrl('')
  }
  const handleOpenVideoDialog = (videoUrl?: string) => {
    if(!videoUrl) return
    setVideoUrl(videoUrl)
    setIsVideoDialogOpen(true)
  }

  const getScriptUsed = (scriptId?: string) => {
    if(!scriptId || !videoPage) return 

    const videoScript = videoPage.script.find(script => script._id === scriptId);

    return videoScript?.name ?? ''
  }

  //OPENAI 
  const handleOpenAIModal = (scriptPartClicked: 'headline' | 'body' | 'cta') => {
    setScriptPart(scriptPartClicked)
    setOpenAIModal(true);
  };

  const handleCloseAIModal = () => {
    setOpenAIModal(false);
  };

  const handleUseOpenAIResponse = (response: string) => {
    console.log(`scriptpart ${scriptPart}`)
    if(scriptPart === 'headline'){
     setScriptHeadline(response);
    } else if(scriptPart === 'body'){
      setScriptBody(response)
    } else if(scriptPart === 'cta'){
      setScriptCta(response)
    }
  };

 // todo next
  const handleReloadPrompt = async () => {
    if (currentPrompt.trim() === '') return
    const maxTokens = 50
    const response = await getSuggestion(currentPrompt, maxTokens);
    if(response.suggestion){
      handleUseOpenAIResponse(response.suggesetion)
    }
  };


  /*useEffect(() => {
    handle
  }, [currentPrompt]);*/

  return (
    <>
      {!!videoPage ? (
        <div className={classes.videoPage}>
          {/* Top Section: Video Page Title */}
          <div className={classes.videoPageHeader}>
            <div className={classes.titleMenu}>
              <h1>{videoPage?.name}</h1>
              <div className={classes.videoPageActions}>
                <IconButton onClick={handleEditDialogOpen} sx={{ color: '#FFBF00' }}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={handleDeleteVideoPage} sx={{ color: '#FFBF00' }}>
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
            <span className={classes.lastUpdatedBy}>{videoPage.lastUpdatedBy.length > 0 ? videoPage.lastUpdatedBy[videoPage.lastUpdatedBy.length - 1] : ''}</span>
          </div>
        <div className={classes.columnsContainer}>
          <div className={classes.videoPageContent}>
            {/* Left Column: Script */}
            <div className={classes.scriptSection}>
              <div className={classes.scriptTitle} style={{display: 'flex', justifyContent: 'space-between'}}>
                <div className={classes.scriptToggle}>
                  <ScriptSvg />
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleMenuOpen}
                    endIcon={<ArrowDropDownIcon sx={{ color: '#000'}}/>}
                  >
                  <span className={classes.title}>{currentScript?.name || 'Script'}</span>
                 </Button>
                 <Tooltip title="Add new script">
                  <IconButton onClick={() => handleAddNewScript(false)} sx={{ color: '#FFBF00' }}>
                      <AddCircleOutlineIcon /> 
                  </IconButton>
                 </Tooltip>
                 <Tooltip title="Clone current script">
                  <IconButton onClick={() => handleAddNewScript(true)} sx={{ color: '#FFBF00' }}>
                      <ContentCopyIcon /> 
                  </IconButton>
                 </Tooltip>
                 <Tooltip title='Save current script'>
                  <IconButton onClick={handleScriptSave} disabled={isLoading}>
                    {isLoading ? <CircularProgress size={24} /> : <SaveIcon sx={{ color: '#FFBF00' }} />}
                  </IconButton>
                 </Tooltip>
                 <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  {videoPage.script.map((script) => (
                    <MenuItem key={script._id} onClick={() => handleScriptSelect(script)}>
                      {script.name}
                    </MenuItem>
                  ))}
                </Menu>
                </div>
                <div className={classes.scriptMenu}>
                </div>
              </div>
               <div className={classes.divider} />
              <div className={classes.scriptPart}>
                <div className={classes.scriptSectionTitle}>
                  <label className={classes.label}>Headline / Hook</label>
                </div>
                <div className={classes.scriptActions}>
                  <textarea
                    className={classes.textarea}
                    value={currentScript?.headline}
                    onChange={e => setScriptHeadline(e.target.value)}
                    onBlur={handleScriptSave}
                  />
                <div className="scriptActionItems">
                  <Tooltip title = "Ask AI for a suggestion">
                    <IconButton onClick={() => handleOpenAIModal('headline')} sx={{ color: '#FFBF00' }}>
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title = "Coming soon: refresh AI suggestions and automatically add to the field">
                    <IconButton onClick={() => ''} sx={{ color: THEME_COLORS.darkGray, cursor: 'help' }}>
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                </div>
              </div>
              <div className={classes.scriptPart}>
                <div className={classes.scriptSectionTitle}>
                  <label className={classes.label}>body</label>
                </div>                
                <div className={classes.scriptActions}>
                  <textarea
                    className={classes.textarea}
                    value={currentScript?.body}
                    onChange={e => setScriptBody(e.target.value)}
                    onBlur={handleScriptSave}
                  />
                <div className="scriptActionItems">
                  <Tooltip title = "Ask AI for a suggestion">
                  <IconButton onClick={() => handleOpenAIModal('body')} sx={{ color: '#FFBF00'}}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                  </Tooltip>
                  <Tooltip title = "Coming soon: refresh AI suggestions and automatically add to the field">
                    <IconButton onClick={() => ''} sx={{ color: THEME_COLORS.darkGray, cursor: 'help' }}>
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                </div>
              </div>
              <div className={classes.scriptPart}>
                <div className={classes.scriptSectionTitle}>
                  <label className={classes.label}>cta</label>
                </div>
                <div className={classes.scriptActions}>
                  <textarea
                    className={classes.textarea}
                    value={currentScript?.cta}
                    onChange={e => setScriptCta(e.target.value)}
                    onBlur={handleScriptSave}
                  />
                  <div className="scriptActionItems">
                  <Tooltip title = "Ask AI for a suggestion">
                  <IconButton onClick={() => handleOpenAIModal('cta')} sx={{ color: '#FFBF00' }}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                  </Tooltip>
                  <Tooltip title = "Coming soon: refresh AI suggestions and automatically add to the field">
                    <IconButton onClick={() => ''} sx={{ color: THEME_COLORS.darkGray, cursor: 'help' }}>
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                </div>
              </div>
            </div>

            {/* Right Column: Generated Videos */}
            <div className={classes.generatedVideosSection}>
              <div className={classes.scriptTitle}>
                <PlayIconSvg />
                <span className={classes.title}>Generated Videos ({videoPage?.generatedVideos.length})</span>
                <IconButton
                  onClick={() => setIsActorsDialogOpen(true)}
                  sx={{ color: '#FFBF00' }}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
                <span
                  className={classes.addActorsText}
                  onClick={() => setIsActorsDialogOpen(true)} // Open Actors Dialog on text click
                  style={{ cursor: 'pointer' }}
                >
                  + Add Actors
                </span>
              </div>
              <div className={classes.divider} />
              {videoPage?.generatedVideos.length > 0 && (
                <button onClick={toggleView} className={classes.generatedVideosNavigationButton}>
                  {showGeneratedVideos ? (
                    <>
                      <ArrowBackIcon sx={{ marginRight: '5px', width: '18px' }} /> Back to Actors List
                    </>
                  ) : (
                    <>
                    Go to Generated Videos <ArrowForwardIcon sx={{ marginLeft: '5px', width: '18px' }} />
                    </>
                  )}
                </button>
              )}
              {/* Conditions for showing content inside Generated Videos Section */}
              {/* If there are no generated videos or we're not in the 'generated videos' view, show actors list */}
              {!showGeneratedVideos || videoPage?.generatedVideos.length === 0 ? (
                actors?.length === 0 ? (
                  // No actors and no generated videos
                  <p>You will find your ad videos here.</p>
                ) : (
                  // Actors available but no generated videos
                  <div className={classes.avatarListSection}>
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
                      <span style={{fontSize: '14px', fontWeight: 'bold', opacity: 0.6}}>Selected Actors</span>
                      <Tooltip title="This first LL version does not support automatic generation of multiple videos at once, so please select one actor to continue. You can switch actors and generate again anytime.">
                        <InfoIcon sx={{ marginLeft: '5px', cursor: 'pointer', color: 'gray', width: '18px' }} />
                      </Tooltip>
                    </div>  
                    {isLoadingAvatars ? (
                      <CircularProgress /> // Show loading spinner while avatars are loading
                    ) : (
                      <div className={classes.avatarList}>
                        {actors.map((actor) => (
                          !!actor.avatar_states[0] && (
                            <div key={actor.avatar_states[0].id} className={classes.avatarItem}>
                              <Checkbox
                                checked={selectedVideoActors.includes(actor.avatar_states[0].id)} // Check if actor is selected
                                onChange={() => {
                                  const actorId = actor.avatar_states[0].id;
                                  setSelectedVideoActors((prevSelectedActors) => 
                                    prevSelectedActors.includes(actorId) 
                                      ? prevSelectedActors.filter((id) => id !== actorId) // Remove if already selected
                                      : [...prevSelectedActors, actorId] // Add if not selected
                                  );
                                }}
                                sx={{ color: THEME_COLORS.gray }}
                              />
                              <img
                                src={actor.avatar_states[0].normal_thumbnail_small}
                                alt={actor.name}
                                className={classes.avatarThumbnail}
                              />
                              <span style={{marginLeft: '10px', textTransform: 'capitalize'}}>{actor.name || actor.avatar_states[0].avatar_name || 'Unknown'}</span>
                            </div>
                          )
                        ))}
                      </div>
                    )}
                    <Button className={classes.generateVideosButton} onClick={handleGenerateVideos} disabled={generatingVideos || !selectedVideoActors.length}>
                       {generatingVideos ? (
                          <CircularProgress size={20} sx={{color: '#000'}}/> 
                        ) : (
                          <LightningArrowIcon width={20} height={20} /> 
                        )}
                      GENERATE VIDEOS
                    </Button>

                    <div style={{ marginTop: '10px',display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                            checked={isTestVideo || !canGenerateVideos(currentWorkspace, user)}
                            onChange={(e) => setIsTestVideo(e.target.checked)}
                            sx={{color: 'gray'}}
                            disabled={!canGenerateVideos(currentWorkspace, user)}
                          />
                      <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                            <span style={{opacity: 0.8}}>Mark as test video</span>
                            {/* Tooltip for explanation */}
                            <Tooltip title={canGenerateVideos(currentWorkspace, user) ? "Test videos have HeyGen watermark" : "You are currently only allowed to generate test videos - check your account or workspace situation"}>
                              <InfoIcon sx={{ marginLeft: '5px', cursor: 'pointer', color: 'gray', width: '18px' }} />
                            </Tooltip>
                      </div>
                    </div>

                  </div>
                )
              ) : (
                // Show the generated videos list when `showGeneratedVideos` is true
              <div>
              {/* Generated videos list */}
              <div className={classes.generatedVideosList}>
              {videoPage?.generatedVideos.map((video: GeneratedVideo) => (
                <div key={video.heygenId} className={classes.generatedVideoItem}>
                  {/* Status Ball */}
                  <Tooltip title={`Status: ${video.status}`}>
                    <div
                      style={{
                        width: '15px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: video.status === 'completed' ? 'green' : video.status === 'failed' ? 'red' : THEME_COLORS.yellow,
                        marginRight: '8px',
                      }}
                    />
                  </Tooltip>


                  {/* Video element with conditional cover */}
                  <div className={classes.videoWrapper} style={{ position: 'relative' }}>
                    <video
                      src={video.video_url}
                      controls
                      className={classes.videoPlayer}
                      width={video.dimension.width}
                      height={video.dimension.height}
                      style={{ opacity: video.status === 'completed' ? 1 : 0.3,}} // Dims video if not completed
                    />

                    {/* Conditional cover for 'processing' and 'failed' */}
                    {video.status !== 'completed' && (
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          borderRadius: '5px',
                          width: '100%',
                          height: '100%',
                          backgroundColor: video.status === 'failed' ? THEME_COLORS.darkGray : THEME_COLORS.yellow,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: 'white',
                          fontWeight: 'bold',
                          fontSize: '14px',
                          textTransform: 'uppercase',
                        }}
                      >
                        {video.status === 'failed' ? 'Failed' : 'Processing'}
                      </div>
                    )}
                    {video.status === 'completed' && !!video.video_url && (
                      <div
                        style={{
                          position: 'absolute',
                          top: 10,
                          left: 30,
                          borderRadius: '5px',
                          width: '50%',
                          height: '50%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: 'white',
                          fontWeight: 'bold',
                          fontSize: '14px',
                          cursor: 'pointer',
                          textTransform: 'uppercase',
                        }}
                      >
                        <IconButton onClick={() => handleOpenVideoDialog(video.video_url)} sx={{color: '#fff'}}>
                          <PlayArrow />
                        </IconButton>
                      </div>
                    )}
                  </div>

                  {/* Actor Name, Refresh Button, Delete Button, Download Button */}
                  <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'space-between', width: '100%'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <span style={{fontSize: '12px', color: THEME_COLORS.darkYellow}}>{getScriptUsed(video.scriptId)}</span>
                      <span>{video.actorName ? video.actorName.slice(0, 10) : 'Actor unknown'}</span>
                    </div>  

                    {/* Reload button to refetch the generated video */}
                    <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                     {video.status !== 'completed' &&
                      <Tooltip title='Refresh video status'>
                        <button
                          onClick={() => updateVideoStatusAndProject(video.heygenId)}
                          className={classes.reloadButton}
                          disabled={videoBeingRefreshed === video.heygenId} // Disable button while loading
                        >
                          {videoBeingRefreshed === video.heygenId ? (
                            <CircularProgress size={22} /> // Show loading icon when refreshing
                          ) : (
                            <RefreshIcon sx={{ width: '22px', height: '22px', color: 'primary' }} /> // Show Refresh icon by default
                          )}
                        </button>
                      </Tooltip>
                     }
 
                       {/* Download Button */}
                      <Tooltip title="Download video">
                        <a
                          href={video.video_url}
                          download
                          style={{ textDecoration: 'none' }}
                        >
                          <button className={classes.videoActionButton}>
                            <Download sx={{ width: '22px', height: '22px', color: 'primary' }} />
                          </button>
                        </a>
                      </Tooltip>
                      <Tooltip title='Delete video (the video will also be deleted from HeyGen)'>
                        <button
                          onClick={() => deleteGeneratedVideo(video._id)}
                          className={classes.videoActionButton}
                          disabled={videoBeingDeleted === video._id} // Disable button while loading
                        >
                          {videoBeingDeleted === video._id ? (
                            <CircularProgress size={22} /> // Show loading icon when refreshing
                          ) : (
                            <DeleteIcon sx={{ width: '22px', height: '22px', color: 'primary' }} /> // Show Refresh icon by default
                          )}
                        </button>
                      </Tooltip>
                    </div>  

                    <VideoAutoRefresh
                      video={video}
                      updateVideoStatusAndProject={updateVideoStatusAndProject}
                    />
                  </div>
                </div>
              ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
          

          {/* Footer Section */}
           {/* OpenAI Modal */}
           {openAIModal &&
          <OpenAIModal
            open={openAIModal}
            onClose={handleCloseAIModal}
            onUse={handleUseOpenAIResponse}
            scriptPart={scriptPart}
            currentPrompt={currentPrompt}
            setCurrentPrompt={setCurrentPrompt}
          />
           }
          {/* Actors Dialog */}
          {videoPage && 
          <ActorsDialog
            open={isActorsDialogOpen}
            actors={videoPage.actors}
            onClose={() => setIsActorsDialogOpen(false)}
            onSelectActors={handleDoneSelectingAvatars}
          />
          }
          {isVideoDialogOpen && !!videoUrl && 
             <VideoDialog
              videoUrl={videoUrl}
              open={isVideoDialogOpen}
              onClose={handleCloseVideoDialog}
            />
          }
          { isEditDialogOpen && 
          <Dialog open={isEditDialogOpen} onClose={handleEditDialogClose}>
            <DialogTitle>Edit Video Page Name</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Video Page Name"
                type="text"
                fullWidth
                variant="outlined"
                value={updatedName}
                onChange={(e) => setUpdatedName(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditDialogClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleUpdateVideoPageName} color="primary">
                Update
              </Button>
            </DialogActions>
          </Dialog>

          }
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

const VideoAutoRefresh = ({ video, updateVideoStatusAndProject }: any) => {
  useEffect(() => {
    let intervalId: any;

    if (video.status !== 'completed') {
      intervalId = setInterval(() => {
        updateVideoStatusAndProject(video.heygenId);
      }, 15000); // 30 seconds
    }

    // Clean up the interval when the status becomes "completed" or component unmounts
    return () => clearInterval(intervalId);
  }, [video.status, video.heygenId, updateVideoStatusAndProject]);

  return null; // This component does not render anything
};

export default VideoPage;
