import React from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface VideoDialogProps {
  videoUrl: string;
  open: boolean;
  onClose: () => void;
}

const VideoDialog: React.FC<VideoDialogProps> = ({ videoUrl, open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent  sx={{padding: '0px !important', background: '#000'}}>
            <video
                src={videoUrl}
                controls
                autoPlay
                style={{
                width: '100%',
                height: '100%',
                }}
            />
      </DialogContent>
    </Dialog>
  );
};

export default VideoDialog;
