// src/theme.ts
import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { THEME_COLORS } from '../context/constants';

const theme= createTheme({
  typography: {
    fontFamily: 'Lexend, sans-serif',
    fontWeightRegular: 400,
    fontWeightBold: 600,
    // You can also customize other typography variants here
  },
  palette: {
    primary: {
      main: '#FFBF00',
    },
    secondary: {
      main: '#000000', // Black
    },
    background: {
      default: '#F4F4F4', // Light gray
    },
    text: {
      primary: '#000000', // Black
      secondary: '#FFFFFF', // White
    },
  },
});
export default theme;
