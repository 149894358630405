// src/components/Dashboard.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const Dashboard = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Typography variant="h4" sx={{ opacity: 0.5 }}>
        Select a project or create a new one to start working!
      </Typography>
    </Box>
  );
};

export default Dashboard;
