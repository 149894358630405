import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tab,
  Tabs,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  IconButton,
  Tooltip,
  Box,
} from '@mui/material';
import { 
  setWorkspaceInvitation, 
  updateWorkspace, 
  addAdminToWorkspace, 
  removeAdminFromWorkspace, 
  addMemberToWorkspace, 
  removeMemberFromWorkspace, 
  addInvitation,
  getWorkspaceById
} from '../../client/api';
import { encodeInvitationKey, fetchUser, USER_WORKSPACE_ROLE } from '../../utils/utils';
import { THEME_COLORS } from '../../context/constants';
import { User, Workspace } from '../../context/types';
import { useNotification } from '../../context/NotificationContext';
import { useTheme } from '@emotion/react';
import { AccountCircle, ContentCopy, Save, ArrowUpward, ArrowDownward, Delete } from '@mui/icons-material';
import { useUserContext } from '../../context/UserContext';
import copy from 'copy-to-clipboard';
import { useGlobalStyles } from '../../theme/global';
import useStyles from './styles';
//import { useGlobalStyles } from '../../theme/global';

interface ConfigurationDialogProps {
  open: boolean;
  onClose: () => void;
  currentWorkspace: Workspace;
}

const ConfigurationDialog: React.FC<ConfigurationDialogProps> = ({ open, onClose, currentWorkspace }) => {
  const [tabValue, setTabValue] = useState<number>(0);
  const [workspaceName, setWorkspaceName] = useState<string>(currentWorkspace.name);
  const [loading, setLoading] = useState<boolean>(false);
  const [invitationUrl, setInvitationUrl] = useState<string>('');
  const { addNotification } = useNotification();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const theme = useTheme();
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const { setCurrentWorkspace, user, setUser } = useUserContext();
  //const globalClasses = useGlobalStyles()

  useEffect(() => {
    setWorkspaceName(currentWorkspace.name);
  }, [currentWorkspace]);

  useEffect(() => {
    const fetchWorkspace = async (workspaceId?: string) => {
        if(!workspaceId) return
        const workspace = await getWorkspaceById(workspaceId);
        setCurrentWorkspace(workspace)
    }

    fetchWorkspace()
  }, [])

  const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleSaveName = async () => {
    if (!user) return;
    try {
      let workspace = { ...currentWorkspace };
      workspace.name = workspaceName;
      setCurrentWorkspace(workspace);
      await updateWorkspace(currentWorkspace._id, workspace);
      const userData = await fetchUser(user._id);
      if (userData._id) {
        setUser(userData);
      }
      addNotification('Workspace updated!', 'success');
    } catch (e) {
      addNotification('Update failed', 'error');
    }
  };

  const handleGenerateInvitation = async (userWorkspaceRole: USER_WORKSPACE_ROLE) => {
    setLoading(true);
    if(!user) return
    try {
      const res = await addInvitation(currentWorkspace._id, userWorkspaceRole, user?._id);
      const invitationKey = res.invitation.key;
      const url = `${process.env.REACT_APP_FRONTEND_URL}/invitation/${currentWorkspace._id}/${encodeInvitationKey(invitationKey)}`;
      setInvitationUrl(url);
      copy(url);
      addNotification('Invitation URL copied to clipboard', 'success');
    } catch (error) {
      console.error('Error generating invitation:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePromoteToAdmin = async (memberId: string) => {
    try {
      const response = await addAdminToWorkspace(currentWorkspace._id, memberId);
      setCurrentWorkspace(response.workspace)
      addNotification('Member promoted to admin', 'success');
    } catch (error) {
      addNotification('Failed to promote member', 'error');
    }
  };

  const handleDemoteToMember = async (adminId: string) => {
    try {
      await removeAdminFromWorkspace(currentWorkspace._id, adminId);
      const response = await addMemberToWorkspace(currentWorkspace._id, adminId);
      setCurrentWorkspace(response.workspace)
      addNotification('Admin demoted to member', 'success');
    } catch (error) {
      addNotification('Failed to demote admin', 'error');
    }
  };

  const handleDeleteUser = async (userId: string, isAdmin: boolean) => {
    try {
      if (isAdmin) {
        await removeAdminFromWorkspace(currentWorkspace._id, userId);
      }
      await removeMemberFromWorkspace(currentWorkspace._id, userId);
      addNotification('User removed', 'success');
    } catch (error) {
      addNotification('Failed to remove user', 'error');
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{currentWorkspace?.name ?? ''} Workspace Settings</DialogTitle>
      <DialogContent>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="configuration tabs" 
        sx={{
            color: THEME_COLORS.yellow, 
            '& .MuiTab-root': { color: 'black' }, // Inactive tab color
            '& .Mui-selected': { color: THEME_COLORS.yellow } // Active tab color
        }}>
          <Tab label="General" />
          <Tab label="Members" />
        </Tabs>
        {tabValue === 0 && (
          <TextField
            label="Workspace Name"
            value={workspaceName}
            onChange={(e) => setWorkspaceName(e.target.value)}
            fullWidth
            margin="normal"
          />
        )}
        {tabValue === 1 && (
          <>
            <Box className={classes.buttonContainer}>
                <Button  variant="contained" color="primary"onClick={() => handleGenerateInvitation(USER_WORKSPACE_ROLE.admin)} disabled={loading} sx={{ marginBottom: 2, marginTop: 3 }}>
                {loading ? <CircularProgress size={24} /> : 'Copy Admin Invitation URL'}
                <ContentCopy />
                </Button>
                <Button onClick={() => handleGenerateInvitation(USER_WORKSPACE_ROLE.member)} disabled={loading} className={globalClasses.secondaryButton}>
                {loading ? <CircularProgress size={24} /> : 'Copy Member Invitation URL'}
                <ContentCopy />
                </Button>
            </Box>
            <List>
              <ListItem>
                <AccountCircle />
                <ListItemText primary={currentWorkspace.owner.email} />
                <Typography sx={{textTransform: 'uppercase', backgroundColor: THEME_COLORS.black, color: THEME_COLORS.darkYellow, fontSize:'12px', borderRadius: '10px', padding: '4px', fontWeight: 'bold', marginLeft: '5px'}}>owner</Typography>
              </ListItem>
              {currentWorkspace.admins
                .filter((admin) => admin._id !== currentWorkspace.owner._id)
                .map((admin) => (
                  <ListItem key={admin._id}>
                    <AccountCircle />
                    <ListItemText primary={admin.email} />
                    <Typography sx={{textTransform: 'uppercase', backgroundColor: THEME_COLORS.lightYellow2, fontSize:'12px', borderRadius: '10px', padding: '4px', fontWeight: 'bold', marginLeft: '5px'}}>admin</Typography>
                    <Tooltip title="Demote to member">
                      <IconButton onClick={() => handleDemoteToMember(admin._id)}>
                        <ArrowDownward />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove user">
                      <IconButton onClick={() => handleDeleteUser(admin._id, true)}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                ))}
              {currentWorkspace.members
                .filter((member) => member._id !== currentWorkspace.owner._id && !currentWorkspace.admins.some((admin) => admin._id === member._id))
                .map((member) => (
                  <ListItem key={member._id}>
                    <AccountCircle />
                    <ListItemText primary={member.email} />
                    <Typography sx={{textTransform: 'uppercase', backgroundColor: THEME_COLORS.lightYellow, fontSize:'12px', borderRadius: '10px', padding: '4px', fontWeight: 'bold', marginLeft: '5px'}}>member</Typography>
                    <Tooltip title="Promote to admin">
                      <IconButton onClick={() => handlePromoteToAdmin(member._id)}>
                        <ArrowUpward />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove user">
                      <IconButton onClick={() => handleDeleteUser(member._id, false)}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                ))}
            </List>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {tabValue === 0 && (
          <Button onClick={handleSaveName} disabled={isLoading}>
            Save {isLoading && <CircularProgress size={24} />}
          </Button>
        )}
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfigurationDialog;
